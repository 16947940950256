import PackageCard from "./packagescard"

export default function Packages() {
  return (
    <div id="packages" className="mt-20">
      <div className="text-light-100">
        <h1 className="text-2xl font-bold text-dark-1004">Package Details</h1>
        <p className="font-light text-dark-900">Most Cost-Effective Packages for You</p>
        <div className="grid grid-cols-2 md:grid-cols-3 justify-center mt-4 mb-20 gap-5">
          <PackageCard name="APS Application including courier " />
          <PackageCard name="University Application" />
          <PackageCard name="Visa Application" />
          <PackageCard name="University Student Hostel Application" />
          <PackageCard name="University Enrollment Assistance" />
          <PackageCard name="Arrival Guidance" />
          <PackageCard name="Accommodation Guidance" />
          <PackageCard name="Arrival Assistance and Accommodation Assistance" />
          <PackageCard name="Airport Pickup and Travel Assistance from Airport to Accomodation (Including Ticket)" />
        </div>
      </div>
    </div>
  )
}
