import React, { useState } from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoIosCall } from "react-icons/io";

const formEndpoint = 'https://formspree.io/f/xzbnzqzo';

export default function ContactForm() {
    const [formData, setFormData] = useState({
        name: '',
        stream: '',
        percentage_cgpa: '',
        IELTS_score: '',
        contact_number: '',
        email: '',
    });

    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(formEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Form submitted successfully');
                setSubmitted(true);
                setFormData({
                    name: '',
                    stream: '',
                    percentage_cgpa: '',
                    IELTS_score: '',
                    contact_number: '',
                    email: '',
                });
            } else {
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    const closeModal = () => {
        setSubmitted(false);
    };

    return (
        <div id="contact" className="flex w-full flex-col mt-20">
            {submitted && (
                <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-black">
                    <div className="bg-white p-8 rounded-md">
                        <p className="text-dark-1004">Thank you! We will get in touch with you soon.</p>
                        <button
                            onClick={closeModal}
                            className="bg-dark-1004 text-white px-4 py-2 mt-4 rounded-md hover:bg-opacity-80"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
            <h3 className="text-2xl font-bold text-dark-1004 mt-20 text-center">Feel Free to Contact Us</h3>

            <ul className='flex justify-center mt-20 gap-5 lg:gap-20 items-center'>
                <li>
                    <a href='tel:+919061161008' className='text-dark-1004'><IoIosCall size={30} /></a>
                </li>
                <li>
                    <a href='mailto:educlearpath@gmail.com' className='text-dark-1004'><MdEmail size={30} /></a>
                </li>
                <li>
                    <a href="https://www.whatsapp.com/" rel="noreferrer" target="_blank" className='text-dark-1004'><FaWhatsapp size={30} /></a>
                </li>
                <li>
                    <a href='https://www.instagram.com/clearpath_edu?igsh=OW9veDZqanRhZWpt' rel="noreferrer" target="_blank" className='text-dark-1004'><FaInstagram size={30} /></a>
                </li>
                <li>
                    <a href='https://www.facebook.com/profile.php?id=61554939397853&mibextid=gik2fB' rel="noreferrer" target="_blank" className='text-dark-1004'><FaFacebook size={30} /></a>
                </li>
            </ul>

            <h1 className="text-2xl font-bold text-dark-1004 mt-20">Free Profile Review</h1>
            <p className="font-light text-dark-900">Feel free to reach out for individualized assistance</p>

            <form
                className="bg-dark-1004 text-light-100 p-8"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-offset="100"
                onSubmit={handleSubmit}
                action={formEndpoint}
                method="POST"
            >
                <label htmlFor="name" className="block mb-2">
                    Name:
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="border border-light-100 rounded-md p-2 w-full bg-dark-1004"
                    />
                </label>

                <label htmlFor="stream" className="block mb-2">
                    Stream in Bachelor's:
                    <input
                        type="text"
                        id="stream"
                        name="stream"
                        value={formData.stream}
                        onChange={handleChange}
                        required
                        className="border border-light-100 rounded-md p-2 w-full bg-dark-1004"
                    />
                </label>

                <label htmlFor="percentage_cgpa" className="block mb-2 mt-4">
                    Percentage/CGPA:
                    <input
                        type="text"
                        id="percentage_cgpa"
                        name="percentage_cgpa"
                        value={formData.percentage_cgpa}
                        onChange={handleChange}
                        required
                        className="border border-light-100 rounded-md p-2 w-full bg-dark-1004"
                    />
                </label>

                <label htmlFor="IELTS_score" className="block mb-2 mt-4">
                    IELTS Score:
                    <input
                        type="text"
                        id="IELTS_score"
                        name="IELTS_score"
                        value={formData.IELTS_score}
                        onChange={handleChange}
                        required
                        className="border border-light-100 rounded-md p-2 w-full bg-dark-1004"
                    />
                </label>

                <label htmlFor="contact_number" className="block mb-2 mt-4">
                    Contact Number:
                    <input
                        type="text"
                        id="contact_number"
                        name="contact_number"
                        value={formData.contact_number}
                        onChange={handleChange}
                        required
                        className="border border-light-100 rounded-md p-2 w-full bg-dark-1004"
                    />
                </label>

                <label htmlFor="email" className="block mb-2 mt-4">
                    Email:
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="border border-light-100 rounded-md p-2 w-full bg-dark-1004"
                    />
                </label>

                <button
                    type="submit"
                    className="bg-white text-dark-1004 px-4 py-2 rounded-md mt-4 hover:bg-opacity-80"
                >
                    Submit
                </button>
            </form>
        </div>
    );
}
