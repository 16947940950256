import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ServicesCard from "./ServicesCard.js"

import c1 from "../assets/Services/c1.jpg"
import c2 from "../assets/Services/c2.jpg"
import c3 from "../assets/Services/c3.jpg"
import c4 from "../assets/Services/c4.jpg"
import c5 from "../assets/Services/c5.jpg"
import c6 from "../assets/Services/c6.jpg"

export default function Services() {
    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1
    };

    return (
        <div id="services" className="flex w-full flex-col text-white">
            <h1 className="text-2xl font-bold text-dark-1004">Services</h1>
            <p className="font-light text-dark-900">Your Wish Is Our Command</p>
            <div className="mb-8">
                <Slider {...settings}>
                    <ServicesCard name="Admission Assistance" img={c1} />
                    <ServicesCard name="Program Selection Guidance" img={c2} />
                    <ServicesCard name="Visa Application Support" img={c3} />
                    <ServicesCard name="Language Proficiecy Guidance" img={c4} />
                    <ServicesCard name="Cultural Adaptation Assistance" img={c5} />
                    <ServicesCard name="Post Arrival Support" img={c6} />
                </Slider>
            </div>
            <div className="bg-dark-1004 text-white p-8" data-aos="fade-up" data-aos-duration="500" data-aos-offset="100">
                <h2 className="text-xl md:text-3xl lg:text-4xl font-bold mb-4">Unlock Your Potential with ClearPath Edu</h2>
                <p className="text-xs md:text-lg lg:text-xl leading-6">
                    Embark on success with ClearPath Edu. From personalized admissions to seamless visa support,
                    we're your partner. Navigate new cultures effortlessly and receive post-arrival assistance.
                    Whether higher education, language proficiency, or career counselling, unlock your potential with us.
                </p>
            </div>
        </div>
    )
}
