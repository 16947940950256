import TestimonialsCard from "./TestimonialsCard"

import b1 from '../assets/Testmonials/b1.jpeg'
import b2 from '../assets/Testmonials/b2.jpeg'
import b3 from '../assets/Testmonials/b3.jpeg'
import b4 from '../assets/Testmonials/b4.jpeg'
import b5 from '../assets/Testmonials/b5.jpeg'
import b6 from '../assets/Testmonials/b6.jpeg'

export default function Testimonials() {
    return (
        <div id="testimonials" className="mt-20 text-white">
            <h1 className="text-2xl font-bold text-dark-1004">Testimonials</h1>
            <p className="font-light text-dark-900">Our Success Stories</p>

            <div className="grid grid-cols-1 md:grid-cols-3 justify-center gap-5">
                <TestimonialsCard name="Aswin" course="Msc Metallic Materials and Technology" unv="TU Freiberg" desc="Thrilled to share that Clearpath Consultancy played a pivotal role in securing my Masters admit at TU Freiberg! Their expert guidance and personalized approach made the journey seamless. If you're dreaming of studying abroad, Clearpath is your gateway to success. #ClearpathConsultancy" img={b1} />
                <TestimonialsCard name="Sonu S" course="Msc Computer Science" unv="University of Paderborn" desc="Excited to announce my admission to the Computer Science program at the University of Paderborn, made possible with the invaluable assistance of Clearpath Consultancy. Their unwavering support and expertise turned my dream into reality. Join me in celebrating this milestone! #ClearpathSuccess" img={b2} />
                <TestimonialsCard name="Harikrishnan" course="Msc Metallic Materials and Technology" unv="TU Freiberg" desc="Exciting news! Just received my admission to the Metallic Material Technology program at TU Freiberg, all thanks to the incredible support and expertise of Clearpath Consultancy. Thrilled to begin this unique journey! Join me in celebrating this achievement. 🎓✨ #ClearpathTriumph" img={b3} />
                <TestimonialsCard name="Anu Devasia" course="..." unv="Hafencity University Hamburg" desc="With my CGPA of 6.7 and an IELTS score of 5.5, I am grateful for ClearPathEdu's support in securing admission to Hafencity University Hamburg.Their guidance has been instrumental in turning my academic and language proficiency into an opportunity for higher education. Thank you, ClearPathEdu, for making this possible." img={b4} />
                <TestimonialsCard name="Maneesh" course="MSc in Systems Engineering" unv="OVGU Magdeburg" desc="I am thrilled to share that with the support of ClearPathEdu, I have successfully obtained admission to OVGU Magdeburg for the MSc in Systems Engineering. Additionally, I have received admits from five other esteemed public universities in Germany. ClearPathEdu's guidance has been invaluable in navigating this complex process, and I'm grateful for their assistance in achieving these milestones. Thank you!" img={b5} />
                <TestimonialsCard name="Lijo Johnny" course="MSc in Scientific Instrumentation" unv="University of Applied Sciences Jena" desc="I am delighted to express my gratitude to ClearPathEdu for their comprehensive guidance, which played a pivotal role in securing my admission to the MSc in Scientific Instrumentation at the University of Applied Sciences Jena. Their support has not only led to this specific admit but has also resulted in two additional admissions, showcasing the effectiveness of their overall guidance. Thank you, ClearPathEdu, for steering me towards these opportunities in my academic journey." img={b6} />
            </div>
        </div>
    )
}
