import './App.css';
import { useEffect } from 'react'
import Navbar from './components/Navbar2';
import Review from './components/Review';
import Footer from './components/Footer2';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App2() {
  useEffect(() => {
    document.title = 'ClearPath Edu';
    AOS.init();
  }, []);
  return (
    <div className=" app px-6 lg:px-20 xl:px-36 bg-light-100">
      <Navbar />
      <Review />
      <Footer />
    </div>
  );
}

export default App2;
