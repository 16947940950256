import React, { useState } from 'react';

const formEndpoint = 'https://formspree.io/f/xjvnkwro';

export default function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    service: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(formEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        setSubmitted(true);
        setFormData({
          name: '',
          email: '',
          mobile: '',
          service: '',
          message: '',
        });
      } else {
        console.error('Error submitting form');
      }
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  const closeModal = () => {
    setSubmitted(false);
  };

  return (
    <div id="contact" className="flex w-full flex-col mt-20">
      {submitted && (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-black">
          <div className="bg-white p-8 rounded-md">
            <p className="text-dark-1004">Thank you! We will get in touch with you soon.</p>
            <button
              onClick={closeModal}
              className="bg-dark-1004 text-white px-4 py-2 mt-4 rounded-md hover:bg-opacity-80"
            >
              Close
            </button>
          </div>
        </div>
      )}

      <h1 className="text-2xl font-bold text-dark-1004">Let's Connect For Free Personal Mentorship</h1>
      <p className="font-light text-dark-900">Get in touch for personalized assistance</p>

      <form
        className="bg-dark-1004 text-light-100 p-8"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-offset="100"
        onSubmit={handleSubmit}
        action={formEndpoint}
        method="POST"
      >
        <label htmlFor="name" className="block mb-2">
          Name:
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="border border-light-100 rounded-md p-2 w-full bg-dark-1004"
          />
        </label>

        <label htmlFor="email" className="block mb-2 mt-4">
          Email:
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="border border-light-100 rounded-md p-2 w-full bg-dark-1004"
          />
        </label>

        <label htmlFor="mobile" className="block mb-2 mt-4">
          Mobile Number:
          <input
            type="text"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            required
            className="border border-light-100 rounded-md p-2 w-full bg-dark-1004"
          />
        </label>

        <label htmlFor="service" className="block mb-2 mt-4">
          Service Required:
          <input
            type="text"
            id="service"
            name="service"
            value={formData.service}
            onChange={handleChange}
            required
            className="border border-light-100 rounded-md p-2 w-full bg-dark-1004"
          />
        </label>

        <label htmlFor="message" className="block mb-2 mt-4">
          Message:
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            rows="4"
            className="border border-light-100 rounded-md p-2 w-full bg-dark-1004"
          ></textarea>
        </label>

        <button
          type="submit"
          className="bg-white text-dark-1004 px-4 py-2 rounded-md mt-4 hover:bg-opacity-80"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
