import { useState } from 'react'
import hero from '../assets/Hero.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faWhatsapp, faFacebook } from "@fortawesome/free-brands-svg-icons";

export default function Hero() {
    const [loaded, setLoaded] = useState(true);

    return (
        <>
            {loaded ?
                <div
                    className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white flex flex-col items-center justify-center"
                >Loading...</div>
                :
                null
            }
            <div id="home" className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative">
                <div className='md:w-3/6 md:p-4' >
                    <img data-aos="flip-right" data-aos-duration="1500" data-aos-offset="200" src={hero} alt="hero" onLoad={() => setLoaded(false)} style={{ width: '100%', borderRadius: '1px', border: '1px solid #7CABF9' }} />
                </div>
                <div className='md:w-3/6' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="100" >
                    <div className="flex flex-col w-full mt-8">
                        <h1 className="text-xl text-dark-900">Welcome to</h1>
                        <h1 className="text-2xl text-dark-1004 font-bold">ClearPath Edu</h1>
                        <p className="text-md font-light text-dark-900 ">Unlock your global potential and navigate your education journey to Europe with expert guidance, ensuring a seamless path to academic excellence.</p>
                    </div>
                    <a href='/review' className='mt-8 lg:mt-4 block text-dark-1004 text-xl'>Get a Free Profile Review<FontAwesomeIcon className='ml-2' icon={faCircleArrowRight} /> </a>

                    <ul className='flex mt-2 gap-4 items-center'>
                        <li>
                            <a href="https://www.whatsapp.com/" rel="noreferrer" target="_blank" className='text-dark-1004'><FontAwesomeIcon size='2xl' icon={faWhatsapp} /></a>
                        </li>
                        <li>
                            <a href='https://www.instagram.com/clearpath_edu?igsh=OW9veDZqanRhZWpt' rel="noreferrer" target="_blank" className='text-dark-1004'><FontAwesomeIcon size='2xl' icon={faInstagram} /></a>
                        </li>
                        <li>
                            <a href='https://www.facebook.com/profile.php?id=61554939397853&mibextid=gik2fB' rel="noreferrer" target="_blank" className='text-dark-1004'><FontAwesomeIcon size='2xl' icon={faFacebook} /></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 h-16 bg-dark-1004 rounded-full object"  data-aos="zoom-out" data-aos-duration="2000" data-aos-offset="100" style={{ clipPath: 'polygon(0 0, 80% 0, 100% 100%, 0% 80%)', width: '60%', margin: '0 auto', height: '5%' }}></div>
            <style jsx>{`
                @media (max-width: 768px) {
                    .absolute {
                        display: none;
                    }
                }
            `}</style>
        </>
    )
}
