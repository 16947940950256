import './App.css';
import { useEffect } from 'react'
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services'
import About from './components/About';
import Packages from './components/Packages';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ContactForm from './components/connect';

function App() {
  useEffect(() => {
    document.title = 'ClearPath Edu';
    AOS.init();
  }, []);
  return (
    <div className=" app px-6 lg:px-20 xl:px-36 bg-light-100">
      <Navbar />
      <Hero />
      <About />
      <Services />
      <Packages />
      <Testimonials />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
