import logo from '../assets/CPU_.png';

export default function Navbar() {
    return (
        <div className='fixed z-50 bg-light-100 w-full top-0 left-0 px-8 py-0 lg:px-20 xl:px-36'>
            <div className="flex justify-between items-center text-white">
                <img src={logo} className="App-logo w-25 h-20 mt-2 mb-2 " alt="logo" />
                <ul className="hidden md:flex">
                    <li className="p-4 text-dark-1004 transition duration-300 ease-in-out transform hover:underline hover:scale-110">
                        <a href="#home" className="hover:underline">Home</a>
                    </li>
                    <li className="p-4 text-dark-1004 transition duration-300 ease-in-out transform hover:underline hover:scale-110">
                        <a href="#about" className="hover:underline">About Us</a>
                    </li>
                    <li className="p-4 text-dark-1004 transition duration-300 ease-in-out transform hover:underline hover:scale-110">
                        <a href="#services" className="hover:underline">Services</a>
                    </li>
                    <li className="p-4 text-dark-1004 transition duration-300 ease-in-out transform hover:underline hover:scale-110">
                        <a href="#packages" className="hover:underline">Package Details</a>
                    </li>
                    <li className="p-4 text-dark-1004 transition duration-300 ease-in-out transform hover:underline hover:scale-110">
                        <a href="#testimonials" className="hover:underline">Testimonials</a>
                    </li>
                    <li className="p-4 text-dark-1004 transition duration-300 ease-in-out transform hover:underline hover:scale-110">
                        <a href="#contact" className="hover:underline">Let's Connect</a>
                    </li>
                </ul>
            </div>
            <style jsx>{`
                @media (max-width: 1000px) {
                    ul {
                        font-size: 10px;
                    }
                }
            `}</style>
        </div>
    );
}
