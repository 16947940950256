import { useState } from 'react';
import about from '../assets/about.jpg';

export default function About() {
    const [setLoaded] = useState(true);

    return (
        <>
            <div id="about" className="flex w-full flex-col md:flex-row gap-5 items-center justify-center text-white relative">
                <div className='md:w-3/6 md:p-4'>
                    <img
                        data-aos="flip-right"
                        data-aos-duration="1500"
                        data-aos-offset="200"
                        src={about}
                        alt="hero"
                        onLoad={() => setLoaded(false)}
                        style={{ width: '100%', borderRadius: '1px', border: '1px solid #7CABF9' }}
                    />
                </div>
                <div className='md:w-3/6 mb-10' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="100">
                    <div className="flex flex-col w-full mt-8">
                        <h2 className="text-2xl font-bold mb-2 text-dark-1004">About Us</h2>
                        <p className="text-md font-light text-dark-900 mb-4">ClearPath Edu empowers students to study in Europe, providing expert guidance from application to cultural insights.</p>
                        <h2 className="text-2xl font-bold mb-2 text-dark-1004">Why Choose Us</h2>
                        <p className="text-md font-light text-dark-900 mb-4">Our team of seasoned advisors provide expert counselling, seamless application process, language support, and cultural insights.</p>
                        <h2 className="text-2xl font-bold mb-2 text-dark-1004">Contact Us</h2>
                        <p className="text-md font-light text-dark-900">Mobile : +91 9061161008</p>
                        <p className="text-md font-light text-dark-900">Email : educlearpath@gmail.com</p>
                        <p className="text-md font-light text-dark-900">Address : Silbermannstraße</p>
                        <p className="text-md font-light ml-16 text-dark-900">Freiberg</p>
                        <p className="text-md font-light ml-16 text-dark-900">09599,Germany</p>
                    </div>
                </div>
            </div>
        </>
    );
}
