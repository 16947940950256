import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
  return (
    <div className="mt-4 bg-dark-1004 rounded-md text-white px-8 py-4">
      <ul className="text-center">
        <li><a href="#home" className="hover:underline">Home</a></li>
        <li><a href="#about" className="hover:underline">About</a></li>
        <li><a href="#services" className="hover:underline">Services</a></li>
        <li><a href="#packages" className="hover:underline">Packages</a></li>
        <li><a href="#testimonials" className="hover:underline">Testimonials</a></li>
        <li><a href="#contact" className="hover:underline">Let's Connect</a></li>
      </ul>
      <div className="mt-4 flex justify-center items-center">
        <div className="flex items-center">
          <a href='tel:+919061161008' className="hover:underline"><p>+91 9061161008</p></a>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="flex items-center">
          <a href='mailto:educlearpath@gmail.com' className="hover:underline"><p>educlearpath@gmail.com</p></a>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="flex items-center mr-4">
          <a href="https://www.whatsapp.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} className="mr-2" /></a>
        </div>
        <div className="flex items-center mr-4">
          <a href="https://www.instagram.com/clearpath_edu?igsh=OW9veDZqanRhZWpt" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} className="mr-2" /></a>
        </div>
        <div className="flex items-center">
          <a href="https://www.facebook.com/profile.php?id=61554939397853&mibextid=gik2fB" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} className="mr-2" /></a>
        </div>
      </div>

      <p className="text-sm font-light text-center mt-4">Copyright © 2023 ClearPath Edu | All Rights Reserved</p>
    </div>
  );
}
